import React, { useState, useRef, useEffect } from 'react';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { isValidPhoneNumber } from 'react-phone-number-input'
import PaginationButtons from '../navigation/PaginationButtons';
import Alert from '../alerts/Alert';
import {
  CustomFieldTemplate,
  CustomInputTemplate,
  CustomErrorListTemplate,
  CustomSelectWidget,
  CustomRadioGroupWidget,
  CustomCheckboxGroupWidget,
  CustomTextareaWidget,
  CustomMarkdownWidget,
  CustomCheckboxWidget,
  CustomPhoneWidget,
  CustomAddressWidget,
  CustomArrayFieldItemTemplate,
  ArrayAddButton,
  CustomTitleFieldTemplate,
} from '../inputs/CustomRjsfInputs';
import { getFormTemplate, postFormSubmission, putFormSubmission } from '../../utils/api';
import CustomFileInput  from '../inputs/CustomFileInput';
import { MemberDashboardMessages, UserLoginState } from '../../utils/constants';


const FabricForm = ({
  person,
  canCreatePerson,
  setLoading,
  csrfToken,
  index,
  isLastForm,
  prevTabTitle,
  goToPrevTab,
  nextTabTitle,
  goToNextTab,
  formSessionId,
  setCurrentFormSessionId,
  templateId,
  formTemplateGroupId,
  registrationForm,
  updateRegistrationForms,
  isReadOnly,
  asModalView,
  userLoginState }) => {

  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [hasFormData, setHasFormData] = useState(false);
  const [responseError, setResponseError] = useState({});
  const formRef = useRef();

  useEffect(() => {
    if (!csrfToken) {
      // This prevents this effect handler from doing anything (i.e. calling getFormTemplate) until the csrfToken is fully populated.
      // When the csrfToken is populated by the parent App component, that triggers a re-render. While calling this effect twice doesn't cause any
      // functional problems, it seems silly to do all this work twice. 
      // Note that this hook will still happen twice when running locally in development mode - this is a deliberate behavior of React's Strict Mode
      // in order to ambiently test that useEffect hooks don't have unexpected side effects. So without this early return, this hook would actually 
      // run 4 times in development mode.
      return;
    }

    const handleGetFormTemplateSuccess = (data) => {
      let schema = data.schema;
      let uiSchema = data.ui_schema;
      if (isLastForm){
        if (!schema.hasOwnProperty('properties')) {
          schema['properties'] = {};
        }
        schema['properties']['finalize'] = {
          'type': 'boolean',
          'default': true,
          'title': ' '
        };

        if (uiSchema.hasOwnProperty('ui:order')) {
          uiSchema['ui:order'].push('finalize');
        }
        uiSchema['finalize'] = { 
          'ui:widget': 'hidden',
          "ui:options": {
            "label": false
          }
        };
      }
      setSchema(schema);
      setUiSchema(uiSchema);
      setHasFormData(data.has_form_data);
      setResponseError({});
      setLoading(false);
    };

    const fetchData = async () => {
      getFormTemplate(templateId, formSessionId, csrfToken, handleGetFormTemplateSuccess, (errorData) => handleSubmitError(errorData));
    };

    setLoading(true);
    fetchData();
  }, [templateId, csrfToken, setLoading, formSessionId, isLastForm, asModalView]);


  const handleNextTab = () => {
    // Don't try to submit if the form is the welcome form or we're in read-only mode
    if (!hasFormData || isReadOnly) {
      goToNextTab();
      return;
    }

    if (!formRef.current.validateForm()) {
      return;
    }

    submit();
  }

  const handlePrevTab = (event) => {
    goToPrevTab();
  }

  const handleChange = (evt) => {
    const formData = evt.formData;

    updateRegistrationForms({
      id: registrationForm.id,
      form: formData,
      templateId: templateId
    }, index);
  };

   const handleSubmitSuccess = (response) => {
    if (response?.data?.close_modal && asModalView) {
        return window.parent.postMessage(MemberDashboardMessages.RELOAD_PARENT, process.env.REACT_APP_FABRIC_BASE_URL);
    }
    if (response?.data?.redirect_url != null) {
      window.location.href = response.data.redirect_url;
      return null;
    }

    if (response.has_warning && canCreatePerson) {
      const warningMessage = `Warning: ${response.messages.join(" ")} Would you like to continue to register as a new member?`;
      if (window.confirm(warningMessage)) {
        submit(true);
        return;
      }
      else {
        setLoading(false);
        return;
      }
    }

    if (response?.form_session_id) {
      setCurrentFormSessionId(response.form_session_id);
    }

    setLoading(false);
    updateRegistrationForms({
      id: response.id,
      form: response.form,
      templateId: response.template_id
    }, index);
    goToNextTab();
  }


  const handleSubmitError = (errorData) => {
    setResponseError(errorData);
    setLoading(false);
  }

  const createFormToSend = (createPersonPotentialDuplicate) => {
    const formKeys = Object.keys(registrationForm.form);
    const visibleFields = {};
    formKeys.forEach(key => {
      const elements = document.getElementsByName(key);
      //only send the existing/rendered/visible fields on form
      if (elements.length > 0) {
        visibleFields[key] = registrationForm.form[key];
      }
      if (registrationForm.form[key] && registrationForm.form[key].constructor === Array){
        visibleFields[key] = registrationForm.form[key];
      }
      //also send explicitly hidden gields
      var hiddenInputs = document.querySelectorAll(`input[type="hidden"][name$='${key}']`);
      if(hiddenInputs.length > 0){
        visibleFields[key] = registrationForm.form[key];
      }
    });

    if (canCreatePerson) {
      visibleFields['create_if_potential_duplicate'] = createPersonPotentialDuplicate;
      visibleFields['form_template_group_id'] = formTemplateGroupId;
    }
    
    return  {
      id: registrationForm.id,
      form_session: formSessionId,
      form: visibleFields,
      person: person.id,
      form_template: templateId,
    };
  }

  const submit = async (createPersonPotentialDuplicate = false) => {
    if(isLastForm && asModalView){
      window.parent.postMessage(MemberDashboardMessages.IS_SAVING, process.env.REACT_APP_FABRIC_BASE_URL);
    }
    setLoading(true);
    setResponseError({});

    const registrationFormToSend = createFormToSend(createPersonPotentialDuplicate);
    if (!registrationForm.id) {
      if (canCreatePerson && userLoginState !== UserLoginState.LOGGED_IN) {
        window.grecaptcha.ready(function() {
          window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
            registrationFormToSend['recaptcha_token'] = token;
            postFormSubmission(registrationFormToSend, formSessionId, csrfToken, handleSubmitSuccess, handleSubmitError);
          });
        });        
      }
      else {
        postFormSubmission(registrationFormToSend, formSessionId, csrfToken, handleSubmitSuccess, handleSubmitError);
      }
    }
    else {
      putFormSubmission(registrationFormToSend.id, registrationFormToSend, formSessionId, csrfToken, handleSubmitSuccess, handleSubmitError);
    }
  };


  const customValidate = (formData, errors, uiSchema) => {
    // Custom address validation
    const customAddressUiSchema = Object.values(uiSchema).find(value => value['ui:widget'] === 'customAddress');
    if (customAddressUiSchema) {
      const addrFormData = formData['address'];
      const allowPartialAddress = customAddressUiSchema['ui:allowPartialAddress'];

      // if there's a value in the "address" prop (which should contain the string value of the address input field itself), we need to validate that there are actual address parts too
      const hasAddressInputValue = !!addrFormData && !!addrFormData['address']
      if (hasAddressInputValue) {
        const keys = ['street', 'city', 'state', 'zip']

        if (allowPartialAddress) {
          // at least one key needs to be populated
          const hasPartialAddress = keys.some(key => addrFormData[key]);
          if (!hasPartialAddress) {
              errors['address'].addError('Please make sure a valid address is selected.');
          }
        }
        else {
          // all keys need to be populated
          const hasFullAddress = keys.every(key => addrFormData[key]);
          if (!hasFullAddress) {
            errors['address'].addError('Please make sure a valid address is selected.');
          }
        }
      }
    }

    return errors;  // this function must always return the original errors object
  }

  const transformErrors = (errors) => {
    const newErrors = errors.filter(error => {
      // Skip if the field is empty and is not required 
      if (error.name  === 'format' || error.name === 'enum' || error.name === 'type' || error.name === 'const') {
        const fieldName = error.property.slice(1);
        const fields = document.getElementsByName(fieldName);
        const isRequired = schema.required?.includes(fieldName);

        if (fields.length > 0  && !isRequired) {
            if (error.name === 'format' && !!!fields[0].value) {
              // formatting error for empty string ('') 
              return false;
            }

            if (error.name === 'enum' && fields[0].value === 'on') {
              // enum error for empty string value (which won't match one of the available enum options)
              return false;
            }

            if (error.name === 'type' && error.params.type === 'boolean') {
              // error for empty boolean value (which won't match the boolean type)
              return false;
            }
            if (error.name === 'type' && !!!fields[0].value) {
              // deal with number fields that are empty (i.e. non-numeric)
              return false;
            }

            if (error.name === 'const') {
              // error for empty boolean value (which won't match one of the available true/false const options)
              return false;
            }
        }

      }

      // HACK: We're abusing a string property for address that actually has multiple subproperties, so we just need to skip this validation error.
      // Actual address validation happens in customValidate().
      if (error.name === 'type' && error.property === '.address') {
        return false; 
      }

      if (error.message === 'must match a schema in anyOf'){
        return false;
      }

      if (error.message === 'must match exactly one schema in oneOf') {
        return false;
      }


      return true;
    }).map(error => {
      if (error.name === "required") {
        error.message = "Please fill out this field.";
        return error;
      }
      if (error.message === "must be equal to one of the allowed values") {
        error.message = "Please fill out this field.";
        return error;
      }
      if (error.message === "must NOT have fewer than 1 items") {
        // array fields - minItems = 1 is used to enforce a required multi-select array field
        error.message = "Please fill out this field."
        return error;
      }
      if (error.name === "pattern" && error.property === ".zip") {
        error.message = "Please enter a 5-digit zip code."
      }   
      if (error.name === "pattern" && error.property === ".ssn") {
        error.message = "SSN must be in the format xxx-xx-xxxx.";
      }
      return error;
    });

    // special case for phone number validation
    const phoneElements = document.getElementsByName('phone_number');
    if (phoneElements.length > 0 && registrationForm.form.phone_number && !isValidPhoneNumber(registrationForm.form.phone_number)) {
      newErrors.push({ message: "Please enter a valid phone number.", name:"format", property: ".phone_number" })
    }

    let uniqueData = [];
    newErrors.forEach((error, errorIndex) => {
      const foundItem = uniqueData.find((item, itemIndex) => {
        return error.message === item.message && item.property === error.property
      });
      if (!foundItem) {
        uniqueData.push(error);
      }
    });
    return uniqueData
  }

  return (<div className='bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 '>
 
    {responseError.has_error &&
      <div className="pt-6 mx-8 mt-6">
        <Alert type="error" messages={responseError.messages} />
      </div>
    }
    {schema &&
      <Form schema={schema}
        uiSchema={uiSchema}
        disabled={isReadOnly}
        className=""
        formData={registrationForm.form}
        onChange={handleChange}
        templates={{
          FieldTemplate: CustomFieldTemplate,
          BaseInputTemplate: CustomInputTemplate,
          ErrorListTemplate: CustomErrorListTemplate,
          ArrayFieldItemTemplate: CustomArrayFieldItemTemplate,
          TitleFieldTemplate: CustomTitleFieldTemplate,
          ButtonTemplates: {
            AddButton: ArrayAddButton,
          }
        }}
        validator={validator}
        widgets={{
          customSelect: CustomSelectWidget,
          customRadioGroup: CustomRadioGroupWidget,
          customCheckboxGroup: CustomCheckboxGroupWidget,
          customCheckbox: CustomCheckboxWidget,
          customTextarea: CustomTextareaWidget,
          customMarkdown: CustomMarkdownWidget,
          customPhoneInput: CustomPhoneWidget,
          customAddress: CustomAddressWidget
        }}
        fields={{
          customFileInput: CustomFileInput
        }}
        showErrorList={false}
        ref={formRef}
        customValidate={customValidate}
        transformErrors={transformErrors}
      // {...customFieldsProps} // Pass custom field props
      >

      <PaginationButtons prevTabBtnText={prevTabTitle}
          handlePreviousTab={handlePrevTab}
          nextTabBtnText={nextTabTitle}
          handleNextTab={handleNextTab}
        />


      </Form>
    }
  </div>
  );
}

export default FabricForm;
